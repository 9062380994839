@import "../../styles/constants.scss";
.mycube-details {
  .title {
    color: #fff;
    font-weight: bold;
    text-decoration: underline;
    font-size: 24px !important;
  }
  .header {
    height: 250px;
    img {
      width: 100%;
      object-fit: cover;
      max-height: 250px;
      height: 100%;
    }
  }
  .code {
    padding: 20px;

    .code-details {
      position: relative;
      border-radius: $border-radius;
      background-color: $color-font;
      padding: 15px;
      text-align: center;
      font-weight: bold;
      font-size: $font-size-title;

      .copy-icon {
        width: 40px;
        height: 100%;
        object-fit: cover;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
