@import "../../styles/constants.scss";

.dashboard {
  height: 100%;
  padding-top: 120px;
  img {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .dashboard {
    padding: 20px;
  }
}
