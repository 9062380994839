$color-primary: #269ab2;
$color-font: #fff;
$color-black: #515151;
$color-black-light: #9e9e9e;
$color-red: #c36;

$border-radius: 8px;

$max-width-xl: 450px;
$max-width-l: 225px;
$max-width-s: 110px;

$font-size-small: 14px;
$font-size: 16px;
$font-size-mid: 18px;
$font-size-title: 24px;

$shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
