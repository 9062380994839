@import "../../../styles/constants.scss";
.shipment-modal-card {
  position: relative;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #269ab2;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 30px;
    box-sizing: border-box;
    flex-direction: column;
    color: $color-font;
    .self-center {
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
  .share-shipment {
    height: 100%;
    box-sizing: border-box;
    background-color: $color-primary;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    .title {
      color: $color-font;
    }

    .content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 50px 15px;
      box-sizing: border-box;
      .text-block {
        color: $color-font;
      }
    }
  }
}
