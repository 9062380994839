@import "../../styles/constants.scss";

.shop-card-button {
  background-color: $color-font;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .container {
    position: relative;
    width: 24px;
    height: 24px;
    margin: 8px;
    .counter {
      position: absolute;
      top: -10px;
      right: -10px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: $color-red;
      justify-self: center;
      align-items: center;
      color: $color-font;
      font-size: 12px;
      text-align: center;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
