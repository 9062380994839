@import "../../styles/constants.scss";

.shop-card {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    flex-grow: 1;
    align-items: center;

    .center-header {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    img {
      width: 60px;
      height: 60px;
      border-radius: $border-radius;
      margin-right: 5px;
      object-fit: cover;
    }

    .title {
      font-weight: bold;
    }

    .short-description {
      font-size: $font-size-small;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 120px;
      overflow: hidden;
    }
  }

  .price {
    font-weight: bold;
    font-size: $font-size-mid;
    display: flex;

    .amount-picker {
      display: flex;
      margin-right: 5px;
      justify-content: center;
      align-items: center;

      .amount-input {
        border-radius: 2px;
        border: 1px solid $color-black-light;
        margin-left: 5px;
        width: 20px;
        height: 20px;
        margin-right: 5px;
        text-align: center;
        color: $color-black;
        font-weight: bold;
      }
    }
    .total-price {
      white-space: nowrap;
      font-size: $font-size-mid;
    }

    .trashCan {
      margin-left: 5px;
    }
  }
}
