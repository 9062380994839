@import "../../styles/constants.scss";

@media only screen and (max-width: 600px) {
  .order-details {
    background-color: $color-primary;
  }
}
.content {
  .share-order {
    width: unset;
    margin-left: 15px;
    margin-right: 15px;
  }
}
.order-details {
  min-height: 100vh;

  .header {
    margin-top: 160px;
    box-sizing: border-box;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    position: relative;
    .order-center-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255,255,255,0.5);
      .order-center {
        font-size: 24px;
        font-weight: bold;
        max-width: 80%;
        text-align: center;
        background-color: white;
        border-radius: $border-radius;
        padding: 15px;
        box-shadow: $shadow;
      }
    }

    @media only screen and (max-width: 600px) {
    }
    .back {
      display: none;
    }
    @media only screen and (max-width: 600px) {
      .back {
        display: flex;
        position: fixed;
        top: 5px;
        left: 0;
        height: 40px;
        width: 40px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
          width: 24px;
          height: 24px;
          object-fit: cover;
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .header {
      margin-top: 0px;
      padding: 0px;
      margin: 0px;
      background-color: $color-font;
    }
  }
  .content {
    .order-card {
      background-color: $color-font;
      margin: 15px;
    }
    .parcle-pickedup {
      text-align: center;
      margin: 15px;
      color: $color-font;
    }
  }
}
