@import "../../styles/constants.scss";

.small-container {
  width: 100%;
  max-width: $max-width-s;
}

.mid-container {
  width: 100%;
  max-width: $max-width-l;
}

.large-container {
  width: 100%;
  max-width: $max-width-xl;
}
