@import "../../styles/constants.scss";

.tabbar-component {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.05);
  min-height: 55px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
  left: 0px;
  right: 0px;
  z-index: 99;
  border-radius: $border-radius;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;

  @media only screen and (min-width: 600px) {
    bottom: initial;
    top: 0px;
    background-color: #269ab2;
    padding: 10px 0px;
  }

  .tabbar-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;

    //remove highlight when clicking on a tab
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    @media only screen and (min-width: 600px) {
      max-width: 600px;
    }

    .tab {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;
      margin-left: 3px;
      margin-right: 3px;
      margin-top: 3px;
      padding-left: 16px;
      padding-right: 5px;

      .tab-icon {
        color: rgba(0, 0, 0, 0.5);
        font-size: 22px;

        @media only screen and (min-width: 600px) {
          padding-left: 0;
        }

        @media only screen and (max-width: 320px) {
          padding-left: 10px;
          padding-right: 10px;
        }
      }

      .badge-container {
        position: relative;
        margin-right: 6px;
        padding-left: -5px;

        @media only screen and (min-width: 600px) {
          margin-right: 0;
        }
      }

      .tab-text {
        color: #fff;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.02em;
        display: flex;
        padding-left: 10px;
        padding-right: 20px;

        //animation to ease in text
        width: auto;
        overflow: hidden;
        flex: 0;
        transition: flex 0.5s ease-out;

        @media only screen and (min-width: 600px) {
          text-align: left;
          font-size: 16px;
          font-weight: bold;
          color: #269ab2;
        }

        @media only screen and (max-width: 320px) {
          display: none;
        }
      }

      &--active {
        background-color: rgba(38, 177, 178, 0.3);
        border-radius: 25px;

        @media only screen and (max-width: 320px) {
          padding-left: 10px;
          padding-right: 10px;
        }

        @media only screen and (min-width: 600px) {
          background-color: inherit;
        }

        .tab-icon {
          font-size: 22px;

          @media only screen and (min-width: 600px) {
            color: #fff;
            padding-left: 0;
          }
        }

        .tab-text {
          display: flex;
          color: $color-primary;
          font-weight: bold;
          flex: 1;

          @media only screen and (min-width: 600px) {
            text-align: left;
            font-size: 16px;
            font-weight: bold;
            color: #fff;
          }

          @media only screen and (max-width: 320px) {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .tabbar-component {
    top: unset;
    bottom: 0;
  }
}
