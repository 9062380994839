@import "../../styles/constants.scss";

.modal {
  height: 100%;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-style: none;
  box-shadow: none;
  display: flex;
  justify-content: flex-end;
  background-color: white;
  backdrop-filter: blur(5px);
}

.modal-small {
  height: 100% !important;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-style: none;
  box-shadow: none;

  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: co;
  justify-content: flex-end;
  .content-modal {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }
}
.modal-body {
  background-color: $color-font;
  border-radius: $border-radius;
  position: relative;
  height: 100%;
}
.modal-body-small {
  background-color: $color-font;
  border-radius: $border-radius;
  position: relative;
}

.modal-body-fullscreen {
  height: 100%;
  min-height: 100%;
  width: 100%;
  background-color: #000;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .rodal-dialog {
    height: 100%;
  }
  .modal-body {
    border-radius: 0;
    .modal-card {
      border-radius: 0;
    }
  }
}

.modal-close {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 15px;
  right: 15px;
  width: 28px;
  height: 28px;
  border-radius: $border-radius;
  background: rgba(50, 50, 50, 0.8);
  z-index: 999;
  cursor: pointer;

  img {
    width: 12px;
    height: auto;
  }

  &:hover {
    background: rgba(0, 0, 0, 1);
  }
}

.rodal .rodal-dialog {
  overflow-y: auto !important;
}

@media only screen and (min-width: 600px) {
  .rodal .rodal-dialog {
    top: initial !important;
    bottom: initial !important;
    right: initial !important;
    left: 50% !important;
    top: 50% !important;

    height: 640px !important;
    width: 500px !important;
    margin-left: -250px !important;
    margin-top: -320px !important;
  }
}

.modal-share .rodal-dialog {
  height: auto !important;
}
