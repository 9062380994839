@import "../../styles/constants.scss";

.order-card {
  background-color: $color-font;

  .order-overview {
    margin-top: 15px;
    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: normal;
      letter-spacing: -0.02em;
    }

    .price-bottom {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid $color-black;
      padding-top: 10px;
      font-weight: bold;
    }
  }
}
