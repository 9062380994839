@import "../../../../styles/constants.scss";

.my-cubes {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  
  .add-card {
    width: 100%;
    max-width: $max-width-l;
    border-radius: $border-radius;
    overflow: hidden;
    border-width: 0;
    box-shadow: $shadow;

    .header {
      background-color: $color-primary;
      display: flex;
      justify-content: center;
      align-content: center;
      min-height: 80%;
      img {
        object-fit: cover;
        width: 100%;
      }
    }
    .footer {
      height: 20%;
      padding: 15px;
      background-color: $color-font;
      border-radius: 0 0 5px 5px;
      .cube {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: flex-end;
        letter-spacing: -0.02em;

        /* Text-Gray */
        color: #515151;
      }
      .add {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        /* identical to box height */
        letter-spacing: -0.02em;

        color: #000000;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
}
