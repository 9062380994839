@import "../../../styles/constants.scss";

.cube-details {
  background-color: $color-primary;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 75px;

  .shipment-container {
    padding: 20px;

    .shipment-card {
      background-color: $color-font;
      margin-top: 15px;
    }
  }
  .title {
    color: $color-font;
    font-size: $font-size-mid;
    text-decoration: underline;
  }
}

@media only screen and (max-width: 600px) {
  .cube-details {
    padding-bottom: 80px;
    margin-top: 0px;
  }
}

.delete-cube {
  background-color: $color-primary;
  padding: 30px;
  border-radius: $border-radius;
  color: $color-font;
  .title {
    color: $color-font;
    font-size: $font-size-title;
    text-decoration: underline;
  }
  .delete-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.loader-container {
  height: 100vh;
}
