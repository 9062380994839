.checkbox-container {
  width: 15px;
  height: 15px;
  position: relative;
  margin-left: 5px;

  .checkbox-background {
    position: absolute;
    background-color: #e0e0e0;
    width: 100%;
    height: 100%;
    border-radius: 2px;
  }
  .checkbox-img {
    position: absolute;
    width: 130%;
    height: 130%;
    object-fit: cover;
    bottom: 0;
    left: 0;
    padding: 0px;
  }
}
