@import "../../styles/constants.scss";

.back-header {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-top: 40px;
  .bubble {
    height: 30px;
    width: 30px;
    cursor: pointer;
    background-color: white;
    border-radius: 50%;
    box-shadow: $shadow;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  .text{
    color: $color-primary;
    font-size: $font-size-title;
    font-weight: bold;
  }
}
