@import "../../styles/constants.scss";

.shop-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 20px;
  margin-bottom: 15px;
  
  .title {
    font-weight: bold;
    font-size: 24px;
    color: $color-primary;
  }

  .right {
  }
}
