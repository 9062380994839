.shipment-card-description-container {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .edit-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .save {
      padding: 5px 30px;
      margin-left: 10px;
    }
  }

  .text {
    margin-right: 10px;
    padding: 5px;
    .textinput-gray {
      padding: 5px;
    }
  }

  img {
    width: 18px;
  }
}
