@import "../../../styles/constants.scss";

.add-to-basket-container {
  box-sizing: border-box;
  background-color: white;
  border-radius: $border-radius;
  height: 100%;
  overflow-x: hidden;

  img {
    width: 100%;
    height: 100vw;
    object-fit: cover;
    border-radius: $border-radius;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .content {
    margin: 15px;
    .title {
      color: $color-primary;
      font-size: $font-size-title;
    }

    .subtitle {
      margin-top: 25px;
      font-weight: bold;
      font-size: $font-size-mid;
    }
  }

  .footer {
    position: fixed;
    transform: translate3d(0, 0, 0);
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px;
    box-shadow: $shadow;
    display: flex;
    background-color: white;
    .left {
      width: 50%;
    }
  }
}
