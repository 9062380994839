@import "../../styles/constants.scss";

.price-details {
  .price {
    font-weight: bold;
    font-size: 24px;

    text-align: center;

    color: $color-primary;
  }

  .price-title {
    font-size: $font-size-small;
    text-align: center;
    font-weight: bold;
  }
}
