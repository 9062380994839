.credits {
  margin-top: 15px;
 
  .btn-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;

    .add-payment {
      width: 30%;
      margin-top: 5px;
    }
  }
}
