@import "../../styles//constants.scss";

.profile-tabbar-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin-right: 15px;
  
    //remove highlight when clicking on a tab
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  
    .item {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      white-space: nowrap;
      color: #515151;
      &-selected {
        white-space: nowrap;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        color: $color-primary;;
      }
    }
    .dot {
      &-selected {
        margin-top: 3px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: $color-primary;;
      }
    }
  }
  