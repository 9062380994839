.profile-tabbar {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  -ms-overflow-style: none; // IE 10+
  scrollbar-width: none; // Firefox
  margin-top: 15px;
  padding-right: 20px;
}

.profile-tabbar::-webkit-scrollbar {
  display: none; // Safari and Chrome
}
