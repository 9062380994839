@import "../../../styles/constants.scss";

.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-my-cube {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;

  .add-my-cube-container {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 20px;
    .button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      .finish-button {
        max-width: $max-width-l;
      }
    }
    .title {
      margin: 0;
      padding: 0;
    }
    .cube-card {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 15px 0;
      cursor: pointer;
      color: white;
      .cube-card-img {
        width: 12px;
        filter: brightness(0) invert(1);
      }

      &:hover {
        opacity: 0.6;
      }
    }

    .bottom-container {
      padding: 20px;
      box-sizing: border-box;
    }

    .header-map {
      height: 300px;
      width: 100%;
    }
  }
}
