@import "../../styles/constants.scss";

.shop-card-container {
  margin: 15px;
  height: 100vh;
  

  .scrollable-items {
    margin-top: 30px;
  }

  .footer {
    background-color: white;
    position: fixed;
    bottom: 60px;
    left: 0;
    right: 0;
    padding: 15px;
    border: 1px solid $color-black-light;

    .amount {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      font-size: $font-size-mid;
      margin-bottom: 15px;
    }
  }
}
