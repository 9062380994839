@import "../../styles/constants.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  .content {
    max-width: $max-width-xl;
    width: 100%;
  }
  .content-full-height{
    max-width: $max-width-xl;
    width: 100%;
    min-height: 100vh;
    height: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    width: 100vw;
    height: 100vh;
    .content {
      max-width: 100%;
      height: 100%;
      width: 100%;
    }
  }
}
