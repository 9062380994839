@import "../../../styles/constants.scss";

@media only screen and (max-width: 600px) {
  .shipment-details {
    background-color: $color-primary;
  }
}
.content {
  .share-shipment {
    width: unset;
    margin-left: 15px;
    margin-right: 15px;
  }
}
.shipment-details {
  min-height: 100vh;

  .header {
    margin-top: 160px;
    box-sizing: border-box;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    position: relative;

    @media only screen and (max-width: 600px) {
    }
    .back {
      display: none;
    }
    @media only screen and (max-width: 600px) {
      .back {
        display: flex;
        position: fixed;
        top: 5px;
        left: 0;
        height: 40px;
        width: 40px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
          width: 24px;
          height: 24px;
          object-fit: cover;
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .header {
      margin-top: 0px;
      padding: 0px;
      margin: 0px;
      background-color: $color-font;
    }
  }
  .content {
    .shipment-card {
      background-color: $color-font;
      margin: 15px;
    }
    .parcle-pickedup {
      text-align: center;
      margin: 15px;
      color: $color-font;
    }
  }
}
