@import "../../styles/constants.scss";

.floating-back {
  display: none;
}

@media only screen and (max-width: 600px) {
  .floating-back {
    display: flex;
    position: fixed;
    top: 15px;
    left: 15px;
    height: 30px;
    width: 30px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: white;
    border-radius: 50%;
    box-shadow: $shadow;
  }

  .floating-inverted {
    display: flex;
    position: fixed;
    top: 5px;
    left: 0;
    height: 40px;
    width: 40px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
