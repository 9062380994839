@import "../../styles/constants.scss";

.primary-button {
  width: 100%;
  background-color: $color-primary;
  border-radius: $border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-font;
  font-size: $font-size;
  font-weight: bold;
  padding: 12px 0;
  cursor: pointer;
  border: unset;

  &-inverted {
    border: unset;
    width: 100%;
    background-color: $color-font;
    border-radius: $border-radius;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    color: $color-primary;
    font-size: $font-size;
    font-weight: bold;
    padding: 12px 0;
  }
  
  &-disabled{
    border: unset;
    width: 100%;
    background-color: $color-black-light;
    border-radius: $border-radius;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    color: $color-font;
    font-size: $font-size;
    font-weight: bold;
    padding: 12px 0;
  }
}
