.field-container {
  position: relative;
  width: 100%;

  .textinput-gray {
    border: 2px solid rgba(14, 155, 216, 0.2);
    box-sizing: border-box;
    border-radius: 5px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 16px;
    letter-spacing: -0.02em;
    padding: 16px;
    width: 100%;
  }

  .read-only{
    opacity: 0.5;
  }

  .eye-field img {
    height: 25px;
    width: 25px;
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input:focus {
    background-color: white !important;
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    outline: none;
  }

  .placeholdeText-error {
    color: #eb5757;
    background-color: white;
  }

  .placeholdeText {
    color: rgba(14, 155, 216, 1);
    background-color: white;
  }

  .textinput-error {
    border: 2px solid #eb5757;
    box-sizing: border-box;
    border-radius: 5px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 16px;
    letter-spacing: -0.02em;
    width: calc(100% - 32px);
    padding: 16px;
    width: 100%;
  }

  label {
    display: inline-block;
    position: absolute;
    left: 15px;
    top: 20px;
    transition: all 150ms ease-in;
    color: #bdbdbd;
  }

  label.field-active {
    transform: translateY(-15px);
    font-size: 0.9em;
    color: rgba(14, 155, 216, 1);
    text-shadow: 1px 0 0 #fff, -1px 0 0 #fff, 2px 0 0 #fff, -2px 0 0 #fff,
      0 1px 0 #fff, 0 -1px 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff;
  }
  label.field-inactive {
    font-size: 0.9em;
    color: rgba(14, 155, 216, 0.5);
    text-shadow: 1px 0 0 #fff, -1px 0 0 #fff, 2px 0 0 #fff, -2px 0 0 #fff,
      0 1px 0 #fff, 0 -1px 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff;

    .placeholdeText {
      color: #5c5c5c;
    }
  }
  .floating-label {
    -webkit-appearance: none !important;
  }

  .eye-field {
    display: inline-block;
    position: absolute;
    top: 16px;
    right: 10px;
  }

  .textinput-not-editable,
  .textinput-not-editable:focus {
    border: 2px solid rgba(14, 155, 216, 0.1);
    box-sizing: border-box;
    border-radius: 5px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 16px;
    letter-spacing: -0.02em;
    padding: 16px;
    width: 100%;
    color: #999;
  }
}

.pac-item {
  padding: 14px;
  font-size: 14px;
}
