@import "../../styles/constants.scss";

.my-cube-card {
  width: 48%;
  box-shadow: $shadow;
  border-radius: $border-radius;
  overflow: hidden;
  margin-bottom: 10px;
  cursor: pointer;
  .header {
    height: 150px;
    background-color: $color-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    .img-placeholder {
      height: 90%;
      object-fit: cover;
    }
    .img-full {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .footer {
    padding: 15px;
    .cube {
      font-weight: bold;
      font-size: $font-size-mid;
    }
  }
}
