@import "../../styles/constants.scss";

.register {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $color-primary;
  padding: 30px;
  border-radius: $border-radius;
  .logo {
    margin-bottom: 15px;
  }

  img {
    width: 50%;
    box-sizing: border-box;
  }


  .form {
    width: 100%;

    .side-by-side {
      display: flex;
    }

    .agb-container {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .agb {
        margin-left: 20px;
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        font-size: 15px;
        letter-spacing: -0.02em;
        color: $color-font;
      }
    }

    .agb-container:focus {
      outline: none !important;
      -webkit-tap-highlight-color: transparent;
      box-shadow: none; /* no box shadow - for some browsers or if you are using Bootstrap */
    }
  }
}

@media only screen and (max-width: 600px) {
  .register {
    height: 100%;
    border-radius: 0px;
    padding: 15px;
  }
}
