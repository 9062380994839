@import '../../styles/constants.scss';

.text-header-block {
    padding: 0px 20px;
    margin-bottom: 15px;
    margin-top: 15px;

    .title {
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 30px;
        text-align: center;
        letter-spacing: -0.02em;
        color: $color-black;
    }

    .subtitle {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: normal;
        text-align: center;
        letter-spacing: -0.02em;
        color: $color-black;
        opacity: 0.75;
        margin-top: 6px;
    }

    &--dark {
        .title {
            color: $color-font;
        }
    
        .subtitle {
            color: $color-font;
        }    
    }
}