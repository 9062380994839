@import "../../styles/constants.scss";

.profile {
  margin-top: 120px;
  .title {
    font-size: $font-size-title;
    color: $color-primary;
    font-weight: bold;
  }
  .content {
    margin-top: 15px;
    box-sizing: border-box;
  }
}

@media only screen and (max-width: 600px) {
  .profile {
    margin-top: 40px;
    border-radius: 0px;
  }
}
