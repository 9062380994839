@import "../../styles/constants.scss";

.shop-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px;
  border-radius: $border-radius;
  overflow: hidden;
  width: 47%;
  margin-bottom: 15px;
  background-color: rgba(11, 161, 227, 0.32);
  img {
    border-radius: $border-radius;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 100%;
    height: 40vw;
    object-fit: cover;
    margin-right: 5px;
  }

  .footer {
    padding: 15px;
    .title {
      font-weight: bold;
      font-size: $font-size-mid;
      line-height: 21px;
      color: #ffffff;
    }

    .subtitle {
      font-size: $font-size-small;
      display: flex;
      align-items: flex-end;
      margin-top: 10px;
      color: #ffffff;
    }
  }
}
