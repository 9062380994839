@import "../../styles/constants.scss";

.titleBarContainer {
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
  margin-left: 0px;
  margin-top: 10px;
  margin-bottom: 20px;

  @media only screen and (max-width: 439px) {
    background-color: #fff;
    margin-left: 0px;
  }

  .greetingContainer {
    display: flex;
  }

  .greeting {
    white-space: pre;
  }

  .userName {
    color: $color-primary;;
  }
}
