@import "../../styles/constants.scss";

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $color-primary;
  padding: 15px;
  box-sizing: border-box;
  border-radius: $border-radius;

  .logo {
    margin-bottom: 15px;
  }

  img {
    width: 50%;
    box-sizing: border-box;
  }

  .seperator {
    border-bottom: 2px solid $color-font;
    border-radius: $border-radius;
    margin-top: 15px;
  }

  .form {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .login {
    height: 100%;
    box-sizing: border-box;
    border-radius: 0px;
  }
}
