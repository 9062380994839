@import "../../styles/constants.scss";

.address {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;

  .co-code{
    font-weight: bold;
  }

  .address-text {
    flex-grow: 1;
    color: $color-font;
  }
  
  .address-text-invert {
    color: $color-black;
  }

  .map {
    width: 20%;
    overflow: hidden;
    background-color: $color-font;
    border-radius: $border-radius;
    flex-grow: 1;
    margin-left: 30px;
  }
}
