@import "../../styles/constants.scss";

.card-header {
  display: flex;
  flex-direction: row;
  padding: 0px;
  align-items: center;

  .card-header-item-symbol {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-primary;
    border-radius: 100%;
    overflow: hidden;
    &-inverted {
      background-color: transparent;
    }
  }

  .img-full {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: $border-radius;
    overflow: hidden;
  }

  .img-center {
    padding: 7px;
    width: 26px;
    height: 26px;
    object-fit: contain;
  }

  .card-header-item-text-inverted {
    .card-header-item-title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: normal;
      letter-spacing: -0.02em;
      color: #fff;
    }
    .card-header-item-subtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: normal;
      letter-spacing: -0.02em;
      //  color: rgba(0, 0, 0, 0.5);
    }
  }

  .card-header-item-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    &-inverted {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 10px;
    }
    .card-header-item-title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: normal;
      letter-spacing: -0.02em;
      color: #515151;
    }
    .card-header-item-subtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: normal;
      letter-spacing: -0.02em;
      //  color: rgba(0, 0, 0, 0.5);
    }
  }
}
