@import "./styles/constants.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

body {
  padding: 0px;
  margin: 0px;
  height: 100vh;
  width: 100vw;
  font-family: Roboto, sans-serif;
  color: $color-black;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.m-20 {
  margin: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-60 {
  margin-top: 60px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-15 {
  margin-right: 15px;
}

.ml-5 {
  margin-left: 5px;
}

.p-20 {
  padding: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.clickable {
  cursor: pointer;
}

.bb-feedback-button-classic {
  top: 30% !important;
}
