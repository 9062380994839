.shop {
  width: 100%;
  padding: 20px;
  padding-top: 0px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.shop-title {
  margin-left: 15px;
  margin-top: 100px;
}

@media only screen and (max-width: 600px) {
  .shop-title {
    margin-left: 15px;
    margin-top: 50px;
  }
}

.shop-coming-soon {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  text-align: center;
  svg {
    margin-bottom: 15px;
  }
}
