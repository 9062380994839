@import "../../styles/constants.scss";

.invite {
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
  
  }
  .title {
    margin-bottom: 15px;
  }
  .invite-body {
    margin-top: 15px;
  }
  .invite-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .button-img {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $color-black;
      border-radius: 50%;
      margin-left: 15px;
      img {
        width: 28px;
        height: 28px;
        object-fit: cover;
      }
      &_primary {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-left: 15px;
        background-color: $color-primary;
        img {
          width: 28px;
          height: 28px;
          object-fit: cover;
        }
      }
    }
  }
}
