.user-profile {
  .logout {
    text-align: center;
    cursor: pointer;
  }
}

@media only screen and (max-width: 600px) {
  .user-profile {
    margin-bottom: 300px;
  }
}
