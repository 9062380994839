@import ".././../styles/constants.scss";

.indicator {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .dot-active {
    width: 12px;
    height: 12px;
    background-color: $color-font;
    border-radius: 50%;
    margin: 0 15px;
  }
  .dot {
    width: 12px;
    height: 12px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
  }
}
